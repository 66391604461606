import React from "react";
import { graphql } from "gatsby";
import Layout from "./../components/Layout";
import VideoHero from "./../components/VideoHero";
import ArticleFilter from "./../components/ArticleFilter";
import { PhotoEssayWrapper } from "./../components/ArticleBody/elements.js";
import ArticleBody from "./../components/ArticleBody";

/**
 * This is a generic page template.
 *
 * TODO: a wrapper for the content.
 *
 */

const ArticleVideosPageTemplate = ({ data, pageContext }) => {
  // console.log(data, pageContext);
  const {
    title,
    editorialAuthor,
    headerImage,
    headerImageMobile,
    editorialShortDescription,
    editorialVideoRemoteVideoUrl,
    editorialIsPhotoEssay,
    richContent,
    venuePdfForDownload,
  } = data.craftEntryInterface;
  // console.log(data.craftEntryInterface);
  const videoURL = data.craftEntryInterface.editorialVideoFile.length
    ? [0].localFile?.publicURL ||
      data.craftEntryInterface.editorialVideoFile[0].url
    : null;
  // console.log(data, pageContext);
  const myColor = "var(--yellow)";
  const textColor = "var(--white)";
  const backgroundColor = "var(--black)";
  return (
    <Layout
      footerColor={myColor}
      backgroundColor={backgroundColor}
      headerColor={textColor}
      skipHeader
      SEOIsArticle
      title={title}
      SEODescription={editorialShortDescription}
      SEOImageURL={
        headerImage &&
        headerImage.length &&
        headerImage[0].SEOImage &&
        headerImage[0].SEOImage.childImageSharp
          ? `https://alserkal.online${headerImage[0].SEOImage.childImageSharp.fixed.src}`
          : null
      }
    >
      <VideoHero
        title={title}
        author={editorialAuthor}
        description={editorialShortDescription}
        videoLocation={videoURL}
        remoteVideoURL={editorialVideoRemoteVideoUrl}
      />
      {editorialIsPhotoEssay ? (
        <PhotoEssayWrapper>
          <ArticleBody
            mainColumn={richContent}
            color={"var(--black)"}
            textColor={"var(--black)" || textColor}
            leftColumn={
              venuePdfForDownload.length && venuePdfForDownload[0].url ? (
                <a href={venuePdfForDownload[0].url} download>
                  <SmallButton>
                    <Download color={"var(--color)"} />
                    Download PDF pack
                  </SmallButton>
                </a>
              ) : null
            }
          />
        </PhotoEssayWrapper>
      ) : null}
      <ArticleFilter backgroundColor={"var(--black)"} defaultType={"videos"} />
    </Layout>
  );
};

export default ArticleVideosPageTemplate;

export const pageQuery = graphql`
  query ($id: String!) {
    craftEntryInterface(id: { eq: $id }) {
      ... on Craft_articles_videos_Entry {
        title
        editorialAuthor
        editorialDate
        editorialShortDescription
        editorialVideoRemoteVideoUrl
        editorialIsPhotoEssay
        headerImage {
          ... on Craft_editorialAssets_Asset {
            SEOImage: localFile {
              childImageSharp {
                fixed(width: 1200) {
                  src
                }
              }
            }
          }
        }
        headerImageMobile{ 
          ... on Craft_editorialAssets_Asset {
            tallImage: localFile {
              publicURL
              childImageSharp {
                fixed(height: 550) {
                  src
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
        editorialVideoFile {
          url
        }
        venuePdfForDownload {
          url
        }
        richContent {
          ... on Craft_richContent_matterportEmbed_BlockType {
            typeHandle
            matterportUrl
          }
          ... on Craft_richContent_podiantPlayer_BlockType {
            podiantUrl
            typeHandle
          }
          ... on Craft_richContent_anghamiPlayer_BlockType {
            anghamiUrl
            typeHandle
          }
          ... on Craft_richContent_soundcloudPlayer_BlockType {
            soundcloudUrl
            typeHandle
          }
          ... on Craft_richContent_spotifyPlayer_BlockType {
            spotifyUrl
            typeHandle
          }

          ... on Craft_richContent_image_BlockType {
            typeHandle
            imageWithCaptionImage {
              ... on Craft_communityAssets_Asset {
                slide: localFile {
                  publicURL
                  childImageSharp {
                    fixed(width: 715, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
              ... on Craft_eventsAssets_Asset {
                slide: localFile {
                  publicURL
                  childImageSharp {
                    fixed(width: 715, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
              ... on Craft_editorialAssets_Asset {
                slide: localFile {
                  publicURL
                  childImageSharp {
                    fixed(width: 715, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
            imageWithCaptionCaption
          }
          ... on Craft_richContent_slideShow_BlockType {
            typeHandle
            images {
              ... on Craft_communityAssets_Asset {
                slide: localFile {
                  publicURL
                  childImageSharp {
                    fixed(height: 460, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
              ... on Craft_editorialVideo_Asset {
                slide: localFile {
                  publicURL
                  childImageSharp {
                    fixed(height: 460, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
              ... on Craft_eventsAssets_Asset {
                slide: localFile {
                  publicURL
                  childImageSharp {
                    fixed(height: 460, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
              ... on Craft_editorialAssets_Asset {
                slide: localFile {
                  publicURL
                  childImageSharp {
                    fixed(height: 460, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
            carouselCaption
          }
          ... on Craft_richContent_richText_BlockType {
            typeHandle
            richText
          }
          ... on Craft_richContent_imageSlider_BlockType {
            typeHandle
            imageSliderImages {
              ... on Craft_communityAssets_Asset {
                slide: localFile {
                  publicURL
                  childImageSharp {
                    fixed(height: 460, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
              ... on Craft_eventsAssets_Asset {
                slide: localFile {
                  publicURL
                  childImageSharp {
                    fixed(height: 460, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
              ... on Craft_editorialAssets_Asset {
                slide: localFile {
                  publicURL
                  childImageSharp {
                    fixed(height: 460, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
            imageSliderCaptions {
              captionLine4
              captionLine3
              captionLine2
              captionLine1
            }
          }
          ... on Craft_richContent_videoWithCaption_BlockType {
            typeHandle
            videoWithCaptionCaption
            videoWithCaptionVideoOnTheLeft
            videoWithCaptionUseCredit
            videoWithCaptionUrl
            videoWithCaptionVideo {
              url
            }
          }
          ... on Craft_richContent_photoWithQuote_BlockType {
            typeHandle
            photoWithQuoteImage {
              ... on Craft_communityAssets_Asset {
                slide: localFile {
                  publicURL
                  childImageSharp {
                    fixed(height: 460, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
              ... on Craft_eventsAssets_Asset {
                slide: localFile {
                  publicURL
                  childImageSharp {
                    fixed(height: 460, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
              ... on Craft_editorialAssets_Asset {
                slide: localFile {
                  publicURL
                  childImageSharp {
                    fixed(height: 460, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
            photoWithQuoteImageOnTheLeft
            photoWithQuoteImageQuote
            photoWithQuoteImageQuoteAttribution
          }
        }
      }
    }
  }
`;

// ... on Craft_editorialVideo_Asset {
//   localFile {
//     publicURL
//   }
// }
// ... on Craft_communityAssets_Asset {
//   localFile {
//     publicURL
//   }
// }
