import styled from "styled-components";

export const VideoBodyDiv = styled.div`
  --accentColor: ${(props) => props.color || "var(--yellow)"};
  --textColor: ${(props) => (props.light ? "var(--black)" : "var(--white)")};
  --backgroundColor: ${(props) =>
    props.light ? "var(--white)" : "var(--black)"};
  background-color: var(--backgroundColor);
  color: var(--textColor);
  max-width: 820px;
  width: 100%;
  padding: 25px 0;
  & > div:first-of-type {
    display: flex;
    justify-content: space-between;
  }
  & h4 {
    color: var(--accentColor);
  }
  & a {
    color: var(--accentColor);
  }
  & p {
    margin-top: 25px;
    margin-bottom: 0;
  }
  & p + aside {
    margin-top: 25px;
  }
  & .desktophidden {
    display: none;
  }
  @media (max-width: 819px) {
    max-width: 375px;
    --outerMargin: 40px;
    padding: 0 var(--outerMargin) 20px var(--outerMargin);
    display: flex;
    flex-direction: column;
    & .mobilehidden {
      display: none;
    }
    & .desktophidden {
      display: inline-block;
      margin-left: auto;
      margin-right: auto;
    }
  }
  @media (max-width: 767px) {
    --outerMargin: 10px;
    & > div:first-child > div:first-child {
      width: 100%;
    }
    & h4 {
      text-align: center;
      width: 100%;
    }
    & p {
      text-align: center;
      width: 100%;
    }
  }
`;
