import styled from "styled-components";

export const VideoHeroDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--black);
  padding-top: 163px;
  & .player-wrapper {
    position: relative;
    width: 100%;
    padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
    & .react-player {
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  & > div:first-of-type {
    border-top: 3px solid var(--yellow);
    width: 100%;
    max-width: calc(
      calc(var(--width) - var(--outerMargin)) - var(--outerMargin)
    );
    height: 700px;
    position: relative;
    display: flex;
    margin-top: -1px;
    /* background-color: gray; */
    justify-content: center;
    & > a {
      justify-self: center;
      align-self: center;
    }
    & > nav {
      position: absolute;
      left: 50px;
      right: 50px;
      bottom: 42.5px;
      display: flex;
      flex-direction: column;
      & p {
        font-size: 11px;
        line-height: 11px;
        color: var(--white);
        & a {
          color: var(--white);
          transition: 0.25s;
          &.on {
            color: var(--yellow);
          }
        }
      }
      & > div {
        width: 100%;
        height: 4px;
        background-color: var(--white);
        position: relative;
        & > div {
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          height: 4px;
          background-color: var(--yellow);
        }
      }
    }
  }
  @media (max-width: 767px) {
    & > div:first-of-type {
      max-width: 100%;
      height: initial;
      margin-bottom: 10px;
      & img {
        width: 100px;
        height: 100px;
      }
    }
  }
`;
