import React from "react";
import PropTypes from "prop-types";
import ReactPlayer from "react-player";
import Video from "react-responsive-video";
import { VideoHeroDiv } from "./elements";
import PlayIcon from "./images/button-video-play@3x.png";
import VideoBody from "./../VideoBody";

const VideoHero = ({
  currentPosition,
  totalLength,
  title,
  author,
  description,
  link,
  videoLocation,
  remoteVideoURL,
}) => {
  const [isPlaying, setIsPlaying] = React.useState(false);
  const [useOtherPlayer /*, setUseOtherPlayer*/] = React.useState(
    Boolean(remoteVideoURL)
  );
  // console.log(videoLocation, remoteVideoURL);
  return (
    <VideoHeroDiv>
      <div>
        {useOtherPlayer ? (
          <div className="player-wrapper">
            <ReactPlayer
              url={remoteVideoURL}
              controls={true}
              className="react-player"
              width={"100%"}
              height={"100%"}
              light
              playing
              playIcon={
                <img src={PlayIcon} alt="play" width={200} height={200} />
              }
            />
          </div>
        ) : (
          <Video mp4={videoLocation} objectFit={`contain`} />
        )}
        {/*isPlaying ? null : (
          <a
            href="/#"
            onClick={(e) => {
              e.preventDefault();
              setIsPlaying(true);
            }}
          >
            <img src={PlayIcon} alt="play" width={200} height={200} />
          </a>
					)*/}
        {useOtherPlayer ? null : (
          <nav>
            <p>
              <a
                href="/#"
                className={isPlaying ? "on" : ""}
                onClick={(e) => {
                  e.preventDefault();
                  setIsPlaying(true);
                }}
              >
                PLAY
              </a>{" "}
              |{" "}
              <a
                href="/#"
                className={isPlaying ? "" : "on"}
                onClick={(e) => {
                  e.preventDefault();
                  setIsPlaying(false);
                }}
              >
                PAUSE
              </a>
            </p>
            <div>
              <div
                style={{ width: `${(100 * currentPosition) / totalLength}%` }}
              />
            </div>
          </nav>
        )}
      </div>
      <VideoBody
        author={author}
        title={title}
        description={description}
        link={link}
      />
    </VideoHeroDiv>
  );
};

export default VideoHero;

VideoHero.propTypes = {
  title: PropTypes.string,
  author: PropTypes.string,
  description: PropTypes.string,
  link: PropTypes.string,
  currentPosition: PropTypes.number,
  totalLength: PropTypes.number,
};
