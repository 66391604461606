import React from "react";
import PropTypes from "prop-types";
import MiscSmallSocial from "./../icons/MiscSocialSmall";
import { VideoBodyDiv } from "./elements";
import { H4BigStrong, H4Big, P } from "./../Typography";

const VideoBody = ({ title, author, description, link }) => (
  <VideoBodyDiv color={"var(--yellow)"}>
    <div>
      <div>
        <H4BigStrong>
          <a href={link}>{title}</a>
        </H4BigStrong>
        {author ? <H4Big>{author}</H4Big> : null}
      </div>
      <MiscSmallSocial color={"var(--yellow)"} extraClass="mobilehidden" />
    </div>
    <P>{description}</P>
    <MiscSmallSocial color={"var(--yellow)"} extraClass="desktophidden" />
  </VideoBodyDiv>
);

export default VideoBody;

export const ImageBody = ({ title, author, description, link }) => (
  <VideoBodyDiv
    color={"var(--purple)"}
    style={{ maxWidth: "820px", marginLeft: "auto", marginRight: "auto" }}
  >
    <div>
      <div>
        <H4BigStrong>
          <a href={link}>{title}</a>
        </H4BigStrong>
        {author ? (
          <H4Big style={{ color: "var(--white)" }}>{author}</H4Big>
        ) : null}
      </div>
      <MiscSmallSocial color={"var(--purple)"} extraClass="mobilehidden" />
    </div>
    <P>{description}</P>
    <MiscSmallSocial color={"var(--purple)"} extraClass="desktophidden" />
  </VideoBodyDiv>
);

VideoBody.propTypes = {
  title: PropTypes.string,
  author: PropTypes.string,
  description: PropTypes.string,
  link: PropTypes.string,
};
